import React from "react";
import { Box, Stack, Grid, GridItem, Flex, Text } from "@chakra-ui/react";
import Container from "components/ui/Container";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "components/Seo";
import { Link } from "gatsby";

const Product: React.FC = () => {
  return (
    <Box py={10}>
      <Seo
        title="Design &amp; Render | Animations | Brand Gallery | DMS"
        description="Simplify your design, realistic renderings and panorama are generated and shared with customers to boost your business. Get design ideas in the design gallery to communicate easily about your project. Connect brand gallery with 3D design that you can browse products with its brand profile and apply its models in your design projects."
        keywords="interior design software, interior design rendering software, 3D interior and exterior design rendering software, online floor plan design software, cloud-based interior design and rendering software platform, online 4K rendering software"
      />

      <Container fluid>
        <Grid
          gap={6}
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        >
          <Flex alignItems="center" maxW="xl">
            <Stack spacing={4}>
              <Text as="h2" color="orange.400" fontWeight="bold" fontSize="3xl">
                Design & Render
              </Text>

              <Text fontWeight="bold" fontSize="xl">
                AiHouse Cloud Design Software
              </Text>

              <Text>
                Why juggle multiple software when you can do everything in
                AiHouse? You can easily design every aspect of your ideas with
                our intelligent workflows. You can quickly deliver compelling
                proposals with 4K visualizations rendered in minutes.
              </Text>
            </Stack>
          </Flex>

          <Flex justify="center" alignItems="center">
            <video autoPlay muted loop>
              <source
                src="https://ai-study1.3vjia.com/prod/banner/demo.mp4"
                type="video/mp4"
              />
            </video>
          </Flex>
        </Grid>
      </Container>

      <Container fluid mt={10}>
        <Stack spacing={8}>
          <Text textAlign="center" fontWeight="bold" fontSize="2xl">
            Work faster and smarter
          </Text>

          <Grid
            gap={6}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          >
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Easy workflows. Powerful toolsets</Text>
                <Text fontWeight="light">
                  On top of a drag and drop experience, we offer 10 parametric
                  modules for customization of storage, flooring, ceiling
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Over 80 million 3D models</Text>
                <Text fontWeight="light">
                  Stop searching for 3D models. Just use our massive library. Or
                  upload your own Max/SketchUp models.
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Exceptional rendering speed</Text>
                <Text fontWeight="light">
                  Render HD to 4K images in only a few minutes. Quickly edit
                  Interactive and panoramic walk-throughs.
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Generate 2D documents</Text>
                <Text fontWeight="light">
                  Get CAD drawings, cutting lists, BOM and quotations with a
                  click.
                </Text>
              </Stack>
            </Box>
          </Grid>
        </Stack>
      </Container>

      <Container
        mt={10}
        py={12}
        backgroundImage={`url(${
          require("../images/banners/product/core-functions-bg.png").default
        })`}
        backgroundPosition="center"
      >
        <Container fluid>
          <Stack spacing={8} color="white">
            <Text textAlign="center" fontWeight="bold" fontSize="2xl">
              Core Functions
            </Text>

            <Grid
              gap={8}
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                xl: "repeat(4, 1fr)",
              }}
            >
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/int-2d-3d.svg"
                    alt="2d into 3d icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Intelligent 2D to 3D</Text>

                <Text color="gray.300" fontWeight="light">
                  Import a picture of your floorplan. Convert it to 3D with a
                  click. Start your project right away
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/convenient-modelling.svg"
                    alt="convenient modelling icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Convenient Modeling</Text>

                <Text color="gray.300" fontWeight="light">
                  Build intricate interior and exterior structure with our
                  SketchUp-like Free Modeling module.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/ready-to-use.svg"
                    alt="ready to use icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Ready-to-use Ideas</Text>

                <Text color="gray.300" fontWeight="light">
                  Out of design ideas sometimes? Just take whatever suits your
                  projects from our sample scenes.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/smart-template.svg"
                    alt="smart template icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Smart Template</Text>

                <Text color="gray.300" fontWeight="light">
                  Automatically decorate your floorplans with hundreds of
                  beautiful templates.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/panorama-editor.svg"
                    alt="panorama editor icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Panorama Editor</Text>

                <Text color="gray.300" fontWeight="light">
                  Put narratives and options on panoramic walk-throughs to make
                  your visuals more persuasive.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/continuous-upgrades.svg"
                    alt="continuous upgrades icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Continuous Upgrades</Text>

                <Text color="gray.300" fontWeight="light">
                  AiHouse is always evolving. It’s on the cloud so you can enjoy
                  any latest features immediately.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/material-editor.svg"
                    alt="material editor icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Material Editor</Text>

                <Text color="gray.300" fontWeight="light">
                  Add more realism to the 3D models in your design by tweaking
                  different materials.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/huge-knowledge-base.svg"
                    alt="huge knowledge base icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Huge Knowledge Base</Text>

                <Text color="gray.300" fontWeight="light">
                  300+ videos of tutorials and real cases. You’ll be inspired by
                  what AiHouse can handle.
                </Text>
              </Stack>
            </Grid>
          </Stack>
        </Container>
      </Container>

      <Container fluid mt={10}>
        <Grid
          gap={6}
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        >
          <Flex alignItems="center" maxW="xl">
            <Stack spacing={4}>
              <Text as="h2" color="orange.400" fontWeight="bold" fontSize="3xl">
                Animation
              </Text>

              <Text fontWeight="bold" fontSize="xl">
                Magic Video
              </Text>

              <Text>
                Offer clients fascinating perspectives to your projects with
                animated walk-throughs. Every aspect of your design would be
                displayed with incredible realism, thanks to real-time ray
                tracing technology.
              </Text>
            </Stack>
          </Flex>

          <Flex justify="center" alignItems="center">
            <Box rounded="lg" overflow="hidden">
              <video autoPlay muted loop>
                <source
                  src={
                    require("../images/banners/product/magic-video.mp4").default
                  }
                  type="video/mp4"
                />
              </video>
            </Box>
          </Flex>
        </Grid>
      </Container>

      <Container fluid mt={10}>
        <Stack spacing={8}>
          <Text textAlign="center" fontWeight="bold" fontSize="2xl">
            Jaw-dropping Visuals
          </Text>

          <Grid
            gap={6}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          >
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Real-time Rendering</Text>
                <Text fontWeight="light">
                  Interact with your scenes and see the renders instantly. You
                  can make creative decisions in a more realistic way.
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Animated Walk-throughs</Text>
                <Text fontWeight="light">
                  Produce different animated walk-throughs simply by adjusting
                  camera angles and applying movement formats.
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Ray Tracing</Text>
                <Text fontWeight="light">
                  Perfectly simulates reflections and shadows, bringing a visual
                  feast to your audience.
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">AiHouse Friendly</Text>
                <Text fontWeight="light">
                  Log in with your AiHouse Cloud Design Software account and
                  directly work on your projects in Magic Video.
                </Text>
              </Stack>
            </Box>
          </Grid>
        </Stack>
      </Container>

      <Container
        mt={10}
        py={12}
        backgroundImage={`url(${
          require("../images/banners/product/core-functions-bg.png").default
        })`}
        backgroundPosition="center"
      >
        <Container fluid>
          <Stack spacing={8} color="white">
            <Text textAlign="center" fontWeight="bold" fontSize="2xl">
              More Features
            </Text>

            <Grid
              gap={8}
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
            >
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/depth-of-field-system.svg"
                    alt="depth of field system icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Depth of Field System</Text>

                <Text color="gray.300" fontWeight="light">
                  Make your scene artistic and cinematic with deep or shallow
                  DOF.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/after-effect.svg"
                    alt="after effect icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">After Effects LUTs</Text>

                <Text color="gray.300" fontWeight="light">
                  Easily adjust the style of your work according to your
                  creative needs.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/animated-transition.svg"
                    alt="animated transition icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Animated Transitions</Text>

                <Text color="gray.300" fontWeight="light">
                  Present your design vividly from 0 to 1 with different
                  transition effects.
                </Text>
              </Stack>
              <Stack spacing={2}>
                <Box height="44px" width="44px">
                  <StaticImage
                    src="../images/icons/product/material-editor-2.svg"
                    alt="material editor 2 icon aihouse india"
                    height={44}
                    width={44}
                  />
                </Box>

                <Text fontSize="xl">Material Editor</Text>

                <Text color="gray.300" fontWeight="light">
                  Quickly customize the materials in your design to generate
                  greater realism.
                </Text>
              </Stack>
            </Grid>
          </Stack>
        </Container>
      </Container>

      <Container fluid mt={10}>
        <Grid
          gap={6}
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        >
          <Flex alignItems="center" maxW="xl">
            <Stack spacing={4}>
              <Text as="h2" color="orange.400" fontWeight="bold" fontSize="3xl">
                Brand Gallery
              </Text>

              <Text fontWeight="bold" fontSize="xl">
                Gallery
              </Text>

              <Text>
                A cost-effective marketing solution for furniture brands to
                target the right audience. By being featured in our public
                library, your products could be used by all AiHouse designers in
                their projects. You will get more leads as designers prefer to
                choose real branded products for their clients.
              </Text>
            </Stack>
          </Flex>

          <Flex justify="center" alignItems="center">
            <Box rounded="lg" overflow="hidden">
              <video autoPlay muted loop>
                <source
                  src={
                    require("../images/banners/product/magic-video.mp4").default
                  }
                  type="video/mp4"
                />
              </video>
            </Box>
          </Flex>
        </Grid>
      </Container>

      <Container fluid mt={10}>
        <Stack spacing={8}>
          <Text textAlign="center" fontWeight="bold" fontSize="2xl">
            More exposure, more business
          </Text>

          <Grid
            gap={6}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          >
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Extra Brand Visibility</Text>
                <Text fontWeight="light">
                  Designers can just drag your products and drop them in their
                  scenes. You get a potential customer each time your products
                  are used.
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Brand Information listed</Text>
                <Text fontWeight="light">
                  Your brand name and website are displayed so you can direct
                  audience to see more of your offerings. You can even have a
                  specific page on our website.
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Immersive Technology</Text>
                <Text fontWeight="light">
                  With AiHouse, you can now immerse customers in their future home 
                  with not only stunning 4K images but also interactive panoramas and 
                  720° walk-throughs, all rendered in minutes.
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              borderColor="gray.200"
              rounded="md"
              shadow="md"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
              userSelect="none"
            >
              <Stack spacing={1}>
                <Text fontSize="xl">Photorealistic Quality</Text>
                <Text fontWeight="light">
                  Our outstanding 4K rendering capability will always make sure
                  your products look amazing in any scenes
                </Text>
              </Stack>
            </Box>
            <Box
              p={4}
              borderWidth={1}
              rounded="md"
              shadow="md"
              as={Link}
              to="/design-gallery/"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize="4xl"
              backgroundColor="orange.300"
              color="white"
              transform="translate(0, 0)"
              _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
              transitionProperty="all"
              transitionDuration="200ms"
              transitionTimingFunction="linear"
            >
              View Design Gallery
            </Box>
          </Grid>
        </Stack>
      </Container>

      <Container fluid mt={10}>
        <Container
          py={20}
          backgroundImage={{
            base: "none",
            md: require("../images/banners/product/design-to-manufacture-system-banner.png")
              .default,
          }}
          backgroundSize="contain"
          backgroundPosition="right"
          backgroundRepeat="no-repeat"
        >
          <Stack spacing={2} w={{ base: "100%", md: "40%" }} maxW="768px">
            <Text as="h4" fontSize="5xl">
              Updating{" "}
              <Text as="span" color="orange">
                Design to Manufacture System (DMS)
              </Text>
            </Text>

          </Stack>
        </Container>

        <Stack spacing={10}>
          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem>
              <StaticImage
                src="../images/banners/product/one-click-order-placing.png"
                alt="one click order placing icon aihouse india"
              />
            </GridItem>

            <GridItem>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  One Click Order Placing
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  Place orders from AiHouse 3D Cloud Design Software to DMS with
                  just one click. No CAD drawings needed. All orders can be
                  synchronized through AiHouse cloud system between designers
                  and factory. This greatly improves efficiency and reduces
                  errors caused by repetitive communication. DMS usually saves
                  designers 3-5 hours for each interior project.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem rowStart={1} colStart={{ base: 1, md: 2 }}>
              <StaticImage
                src="../images/banners/product/intelligent-order-splitting.png"
                alt="intelligent order splitting icon aihouse india"
              />
            </GridItem>

            <GridItem rowStart={{ base: 2, md: 1 }} colStart={1}>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Intelligent Order Splitting
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  Install hardware, analyze manufacture requirements down to
                  every detail and split the order into separated parts for
                  production. DMS does all the job. Quotation can also be
                  generated and synchronized with order information
                  automatically
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem>
              <StaticImage
                src="../images/banners/product/board-planning-optimizations.png"
                alt="board planning optimizations icon aihouse india"
              />
            </GridItem>

            <GridItem>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Board Planning Optimization
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  Panels from different orders can be planned together for
                  maximum board utilization based on production requirements.
                  With the advanced optimization algorithm, the board usage rate
                  can reach 95%. Your production waste would be reduced
                  considerably.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem rowStart={1} colStart={{ base: 1, md: 2 }}>
              <StaticImage
                src="../images/banners/product/connect-with-machines.png"
                alt="connect with machines icon aihouse india"
              />
            </GridItem>

            <GridItem rowStart={{ base: 2, md: 1 }} colStart={1}>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Connect with Machines
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  Compatible with various machines, such as carving machines,
                  CNC saw, NC machine, NC drills that recognize NC, CSV, MPR,
                  DXF files, etc.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem>
              <StaticImage
                src="../images/banners/product/mpm-barcode-tracking.png"
                alt="mpm barcode tracking icon aihouse india"
              />
            </GridItem>

            <GridItem>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  MPM Barcode Tracking
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  A unique barcode will be assigned to each panel for multiple
                  purposes such as production process tracking, packaging,
                  shipping status, etc. Digitalization helps factories reduce
                  mistakes caused by human errors.
                </Text>
              </Stack>
            </GridItem>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
};

export default Product;
